import { useMutation, useQuery } from "@apollo/client"
import { Alert } from "antd"
import { useEffect, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

import KashVaultNoYearLogo from "../../assets/images/KashVaultNoYearLogo.png"
import ProgramLogo from "../../assets/images/ProgramLogo.png"
import TireProgramLogo from "../../assets/images/TireProgramLogo.png"
import WebastoProgramLogo from "../../assets/images/WebastoProgramLogo.png"
import WebastoTileImage from "../../assets/images/WebastoTileImage.png"
import KiaKashTileImage from "../../assets/images/KiaKashTileImage.png"
import TireSourceTileImage from "../../assets/images/TireSourceTileImage.png"
import KiaWindshieldTileImage from "../../assets/images/KiaWindshieldTileImage.png"
import KiaWindshieldLogo from "../../assets/images/KiaWindshieldLogo.png"
import KiaBatteryTileImage from "../../assets/images/KiaBatteryTileImage.png"
import KiaBatteryLogo from "../../assets/images/KiaBatteryLogo.png"
import {
  GET_CURRENT_PROMOTIONS,
  REQUEST_ENROLLMENT_LINK,
} from "../../components/EnrollmentForm/graphql"
import Footer from "../../components/Layout/Footer"
import Header from "../../components/Layout/Header"
import {
  ProgramButton,
  ProgramDetails,
  ProgramTile,
} from "../../components/SignIn/styled"
import Spinner from "../../components/Spinner"
import Container from "../../components/styled/Container.styled"
import { errorTimeout } from "../../constants/general"
import { CommonPaths } from "../../constants/paths"
import RESET_ALERT from "../../constants/resetAlert"
import { useAuthStatus } from "../../hooks/useAuthStatus"
import useGetMyPermissions from "../../hooks/useGetMyPermissions"
import EnrollmentForm from "./EnrollmentForm"
import { IGetIndividualPromotions } from "../../types/graphqlResponse"
import { getFormattedDate } from "../../utils/date"

function EnrollmentFormWrapper() {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { loggedIn } = useAuthStatus()

  const state = location.state as { from: Location }
  const from = state ? state.from.pathname : CommonPaths.HOME_PATH

  // Consumer Side - tokens from URL
  const token = searchParams.get("token")

  const { loading: loadingPermissions } = useGetMyPermissions()

  const [alert, setAlert] = useState(RESET_ALERT)

  const [showKiaKashEnrollment, setShowKiaKashEnrollment] = useState(false)

  const [getEnrollmentLink, { loading: enrolling }] = useMutation<{
    requestSingleSignOn: string
  }>(REQUEST_ENROLLMENT_LINK, {
    onCompleted(data) {
      window.location.replace(data.requestSingleSignOn)
    },
    onError(error) {
      setAlert({
        type: "error",
        message: error.message,
      })
    },
  })

  // Fetch the current promotions
  const { data, loading } = useQuery(GET_CURRENT_PROMOTIONS)

  const promotions = data?.requestCurrentPromotions || []

  const kiaKashPromotion = promotions.find(
    (promo: IGetIndividualPromotions) => promo.fromProgram === "KIA_KASH"
  )
  const kiaTirePromotion = promotions.find(
    (promo: IGetIndividualPromotions) => promo.fromProgram === "KIA_TIRE"
  )

  const kiaKashPromotionPeriod = kiaKashPromotion
    ? `${getFormattedDate(kiaKashPromotion.startDate)} - ${getFormattedDate(
      kiaKashPromotion.endDate
    )}`
    : "Loading..."

  const kiaTirePromotionPeriod = kiaTirePromotion
    ? `${getFormattedDate(kiaTirePromotion.startDate)} - ${getFormattedDate(
      kiaTirePromotion.endDate
    )}`
    : "Loading..."

  // Resetting Alert
  useEffect(() => {
    if (alert.message) {
      setTimeout(() => {
        setAlert(RESET_ALERT)
      }, errorTimeout)
    }
  }, [alert.message])

  useEffect(() => {
    if (loggedIn && !loadingPermissions) {
      navigate(from, { replace: true })
    }
  }, [from, navigate, loggedIn, loadingPermissions])

  // Kia Kash enrollment function
  function onKiaKashEnrollment() {
    setShowKiaKashEnrollment(true)
  }

  // Kia Tire login function
  function onKiaTireLogin() {
    if (token) {
      getEnrollmentLink({
        variables: {
          programCode: "KIA_TIRE",
          token,
          isNewEnrolment: true,
        },
      })
    }
  }

  // Webasto login function
  function onWebastoLogin() {
    if (token) {
      getEnrollmentLink({
        variables: {
          programCode: "WEBASTO",
          token,
          isNewEnrolment: true,
        },
      })
    }
  }

  // Kia Windshield login function
  function onKiaWindshieldLogin() {
    if (token) {
      getEnrollmentLink({
        variables: {
          programCode: "KIA_VWBKV",
          token,
          isNewEnrolment: true,
        },
      })
    }
  }
  // Kia intersatet login function
  function onKiaInterstateLogin() {
    if (token) {
      getEnrollmentLink({
        variables: {
          programCode: "KIA_IB",
          token,
          isNewEnrolment: true,
        },
      })
    }
  }

  if (showKiaKashEnrollment) {
    return <EnrollmentForm />
  }

  return (
    <>
      {/* <Header showNone /> */}
      <div className="container-fluid pb-4">
        <div className="row text-center bg-dark-subtle py-4">
          <div className="col">
            <img
              src={KashVaultNoYearLogo}
              width="120px"
              alt="Kia Kash Vault"
              className="pb-3"
            />
            <h5 className="text-center pt-4 border-top">Welcome to the</h5>
            <h1 className="display-4 fw-bold">Kash Vault Programs</h1>
            <p>
              These programs give you exciting opportunities to earn KASH!
              <br />
              Select a program to enroll, make Sales Claims, and earn KASH on a
              prepaid Visa Reward Card.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center mb-3">
          <div className="col-md-4 py-4">
            <ProgramTile className="card">
              <div className="text-center">
                <img
                  src={KiaKashTileImage}
                  width="100%"
                  alt="Kia Kash Vault Products"
                />
                <img
                  src={KashVaultNoYearLogo}
                  width="40%"
                  alt="Kia Kash Vault"
                />
              </div>

              <ProgramDetails className="card-body">
                <div>Program Name</div>
                Kia Kash Vault
                <div>Eligible Product(s)</div>
                Kia Genuine Accessories
                <div>Eligible Participants</div>
                Dealer Parts & Service employees
                <div>Promotion Period</div>
                {kiaKashPromotionPeriod}
              </ProgramDetails>

              <div className="card-footer">
                <ProgramButton
                  onClick={onKiaKashEnrollment}
                  type="button"
                  disabled={enrolling}
                  className="btn"
                >
                  Continue To Program
                </ProgramButton>
              </div>
            </ProgramTile>
          </div>
          <div className="col-md-4 py-4">
            <ProgramTile className="card">
              <div className="text-center">
                <img
                  src={KiaBatteryTileImage}
                  width="100%"
                  alt="Kia Genuine Batteries"
                />
                <img
                  src={KiaBatteryLogo}
                  width="50%"
                  alt="Kia Fall Battery Promotion"
                />
              </div>

              <ProgramDetails className="card-body">
                <div>Program Name</div>
                Kia Fall Battery Promotion
                <div>Eligible Product(s)</div>
                KIA Batteries
                <div>Eligible Participants</div>
                Service Managers
                <div>Promotion Period</div>
                09/01/2024-10/31/2024
              </ProgramDetails>

              <div className="card-footer">
                <ProgramButton
                  onClick={onKiaInterstateLogin}
                  type="button"
                  disabled={enrolling}
                  className="btn"
                >
                  Continue To Program
                </ProgramButton>
              </div>
            </ProgramTile>
          </div>
          <div className="col-md-4 py-4">
            <ProgramTile className="card">
              <div className="text-center">
                <img
                  src={KiaWindshieldTileImage}
                  width="100%"
                  alt="Kia Windshield Wiper Products"
                />
                <img
                  src={KiaWindshieldLogo}
                  width="50%"
                  alt="Kia Windshield Wiper Contest"
                />
              </div>

              <ProgramDetails className="card-body">
                <div>Program Name</div>
                Kia Windshield Wiper Contest
                <div>Eligible Product(s)</div>
                Flat Wiper Blades, Rear Wiper Blades
                <div>Eligible Participants</div>
                Parts Managers
                <div>Promotion Period</div>
                09/01/2024 - 10/31/2024
              </ProgramDetails>

              <div className="card-footer">
                <ProgramButton
                  onClick={onKiaWindshieldLogin}
                  type="button"
                  disabled={enrolling}
                  className="btn"
                >
                  Continue To Program
                </ProgramButton>
              </div>
            </ProgramTile>
          </div>

          <div className="col-md-4 py-4">
            <ProgramTile className="card">
              <div className="text-center">
                <img
                  src={TireSourceTileImage}
                  width="100%"
                  alt="Tire Source Products"
                />
                <img
                  src={TireProgramLogo}
                  width="40%"
                  alt="Kia Tire Source Vault"
                />
              </div>

              <ProgramDetails className="card-body">
                <div>Program Name</div>
                Kia Tire Source Vault
                <div>Eligible Product(s)</div>
                Kia Tires
                <div>Eligible Participants</div>
                Dealer Service Consultants
                <div>Promotion Period</div>
                {kiaTirePromotionPeriod}
              </ProgramDetails>

              <div className="card-footer">
                <ProgramButton
                  onClick={onKiaTireLogin}
                  type="button"
                  disabled={enrolling}
                  className="btn"
                >
                  Continue To Program
                </ProgramButton>
              </div>
            </ProgramTile>
          </div>

          <div className="col-md-4 py-4">
            <ProgramTile className="card">
              <div className="text-center">
                <img
                  src={WebastoTileImage}
                  width="100%"
                  alt="Webasto Products"
                />
                <img
                  src={WebastoProgramLogo}
                  width="40%"
                  alt="Webasto Kash Vault"
                />
              </div>

              <ProgramDetails className="card-body">
                <div>Program Name</div>
                Webasto Kash Vault
                <div>Eligible Product(s)</div>
                Go Dual Voltage Portable Wall Charger
                <div>Eligible Participants</div>
                F&I Managers, Parts Managers, Parts Specialists, Sales
                Consultants, Service Managers, Service Advisors, Service
                Consultants
              </ProgramDetails>

              <div className="card-footer">
                <ProgramButton
                  onClick={onWebastoLogin}
                  type="button"
                  disabled={enrolling}
                  className="btn"
                >
                  Continue To Program
                </ProgramButton>
              </div>
            </ProgramTile>
          </div>
        </div>

        {enrolling && (
          <div className="text-center">
            <Spinner />
            <p>Redirecting...</p>
          </div>
        )}

        {alert.message && <Alert {...alert} />}
      </div>
      <Footer />
    </>
  )
}

export { EnrollmentFormWrapper }
